import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  error: {},
  frGrp: {
    marginBottom: "1rem",
    "& textarea": {
      flex: "1",
      width: "100%",
      borderBottom: "2px solid var(--darkSilver)",
      fontSize: "17px",
      fontWeight: "var(--fontWeight700)",
      borderRadius: "0",
      color: "var(--darkMore)",
      padding: "6px 8px",
      "&:focus-visible": {
        outline: "0",
        borderBottomColor: "var(--lightGrey)",
      },
    },
  },
});
