import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  merCard: {
    borderRadius: "10px",
    backgroundColor: "var(--white)",
    textAlign: "center",
    boxShadow: "rgb(140 149 159 / 2%) 0px 8px 24px 0px",
    [breakpoints.SCREEN_MD_MIN]: {
      padding: "30px",
    },
    [breakpoints.SCREEN_MD_MAX]: {
      padding: "20px",
    },
  },
  merCardImage: {
    display: "flex",
    justifyContent: "center",
  },
  merCardLink: {
    color: "blue",
    cursor: "pointer",
  },
});
