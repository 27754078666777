import FooterComponent from "components/Layout/FooterComponent";
import HeaderComponent from "components/Layout/HeaderComponent";
import PaymentLinkComponent from "components/PaymentLink/PaymentLinkComponent";
import React from "react";

const PaymentLinkContainer = () => {
  return (
    <>
      <HeaderComponent />
      <PaymentLinkComponent />
      <FooterComponent />
    </>
  );
};

export default PaymentLinkContainer;
