import { Modal } from "antd";

const ModalComponent = ({
  children,
  extraClass,
  closable,
  centered,
  ...rest
}: any) => {
  return (
    <Modal
      className={extraClass}
      destroyOnClose={true}
      centered={centered ? centered : false}
      closable={closable ? false : true}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
