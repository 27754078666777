import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  btn: {
    overflow: "hidden",
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    verticalAlign: "middle",
    alignItems: "center",
    color: "var(--dark)",
    textAlign: "center",
    userSelect: "none",
    fontWeight: "var(--fontWeight700)",
    fontSize: "15px",
    lineHeight: "1.5",
    borderWidth: "2px",
    borderRadius: "10px",
    borderStyle: "solid",
    padding: "0.735rem 20px",
    borderColor: "transparent",
    backgroundColor: "transparent",
    fontFamily: "'Nunito', sans-serif",
    transition: "all 0.3s ease-in-out",
    WebkitTransition: "all 0.3s ease-in-out",
    " *": {
      transition: "all 0.3s ease-in-out",
      WebkitTransition: "all 0.3s ease-in-out",
    },
    "&:before, &:after": {
      top: "0",
      left: "0",
      content: '""',
      height: "100%",
      position: "absolute",
      transform: "translateX(-100px) skewX(-15deg)",
      WebkitTransform: "translateX(-100px) skewX(-15deg)",
    },
    "&:before": {
      width: "60px",
      opacity: "0.5",
      filter: "blur(30px)",
      background: "rgba(255, 255, 255, 0.5)",
    },
    "&:after": {
      left: "30px",
      width: "30px",
      filter: "blur(5px)",
      background: "rgba(255, 255, 255, 0.2)",
    },
    "&.svgY": {
      "& svg": {
        transform: "rotate(-45deg)",
        WebkitTransform: "rotate(-45deg)",
      },
    },
    "&:hover": {
      "&:before, &:after": {
        transition: "all 0.9s ease",
        WebkitTransition: "all 0.9s ease",
        transform: "translateX(400px) skewX(-15deg)",
        WebkitTransform: "translateX(400px) skewX(-15deg)",
      },
      "&::before": {
        opacity: "1",
      },
      "&.svgAnimationYX": {
        "& svg": {
          animation: "svgAnimationYX 0.7s infinite ease-in-out",
        },
      },
      "&.svgAnimationX": {
        "& svg": {
          animation: "svgAnimationX 0.7s infinite ease-in-out",
        },
      },
      "&.svgAnimationY": {
        "& svg": {
          animation: "svgAnimationY 0.7s infinite ease-in-out",
        },
      },
      "&:active": {
        transform: "scale(0.95)",
        WebkitTransform: "scale(0.95)",
      },
    },
  },
  btnPrimary: {
    color: "var(--primary)",
    borderColor: "var(--primary)",
    boxShadow: "0 6px 12px rgba(89 128 220 / 10%)",
    WebkitBoxShadow: "0 6px 12px rgba(89 128 220 / 10%)",
    "& svg": {
      "& > path": {
        fill: "var(--primary)",
      },
    },
    "&:hover, &.active": {
      color: "var(--white)",
      borderColor: "var(--primary)",
      backgroundColor: "var(--primary)",
      boxShadow: "0 6px 20px rgba(89 128 220 / 30%)",
      WebkitBoxShadow: "0 6px 20px rgba(89 128 220 / 30%)",
      "& svg": {
        "& > path": {
          fill: "var(--white)",
        },
      },
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        boxShadow: "0 6px 12px rgba(89 128 220 / 30%)",
        WebkitBoxShadow: "0 6px 12px rgba(89 128 220 / 30%)",
      },
  },
  btnPrimaryFill: {
    color: "var(--white)",
    // borderColor: "var(--primary)",
    backgroundColor: "var(--primary)",
    boxShadow: "0 6px 12px rgba(89 128 220 / 10%)",
    WebkitBoxShadow: "0 6px 12px rgba(89 128 220 / 10%)",
    "& svg": {
      "& > path": {
        fill: "var(--white)",
      },
    },
    "&:hover, &.active": {
      color: "var(--white)",
      borderColor: "var(--primary)",
      boxShadow: "0 6px 20px rgba(89 128 220 / 30%)",
      WebkitBoxShadow: "0 6px 20px rgba(89 128 220 / 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        boxShadow: "0 6px 12px rgba(89 128 220 / 30%)",
        WebkitBoxShadow: "0 6px 12px rgba(89 128 220 / 30%)",
      },
  },
  btnSecondary: {
    color: "var(--secondary)",
    // borderColor: "var(--secondary)",
    boxShadow: "0 6px 12px rgba(69 74 86 / 10%)",
    WebkitBoxShadow: "0 6px 12px rgba(69 74 86 / 10%)",
    "& svg": {
      "& > path": {
        fill: "var(--secondary)",
      },
    },
    "&:hover, &.active": {
      color: "var(--white)",
      borderColor: "var(--secondary)",
      backgroundColor: "var(--secondary)",
      boxShadow: "0 6px 20px rgba(69 74 86 / 30%)",
      WebkitBoxShadow: "0 6px 20px rgba(69 74 86 / 30%)",
      "& svg": {
        "& > path": {
          fill: "var(--white)",
        },
      },
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        boxShadow: "0 6px 12px rgba(69 74 86 / 30%)",
        WebkitBoxShadow: "0 6px 12px rgba(69 74 86 / 30%)",
      },
  },
  btnSecondaryFill: {
    color: "var(--white)",
    // borderColor: "var(--secondary)",
    backgroundColor: "var(--secondary)",
    boxShadow: "0 6px 12px rgba(69 74 86/ 10%)",
    WebkitBoxShadow: "0 6px 12px rgba(69 74 86/ 10%)",
    "& svg": {
      "& > path": {
        fill: "var(--white)",
      },
    },
    "&:hover, &.active": {
      color: "var(--white)",
      // borderColor: "var(--secondary)",
      boxShadow: "0 6px 20px rgba(69 74 86/ 30%)",
      WebkitBoxShadow: "0 6px 20px rgba(69 74 86/ 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        boxShadow: "0 6px 12px rgba(69 74 86/ 30%)",
        WebkitBoxShadow: "0 6px 12px rgba(69 74 86/ 30%)",
      },
  },
  btnDangerFill: {
    color: "var(--white)",
    // borderColor: "var(--secondary)",
    backgroundColor: "var(--danger)",
    boxShadow: "0 6px 12px rgba(69 74 86/ 10%)",
    WebkitBoxShadow: "0 6px 12px rgba(69 74 86/ 10%)",
    "& svg": {
      "& > path": {
        fill: "var(--white)",
      },
    },
    "&:hover, &.active": {
      color: "var(--white)",
      // borderColor: "var(--secondary)",
      boxShadow: "0 6px 20px rgba(69 74 86/ 30%)",
      WebkitBoxShadow: "0 6px 20px rgba(69 74 86/ 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        boxShadow: "0 6px 12px rgba(69 74 86/ 30%)",
        WebkitBoxShadow: "0 6px 12px rgba(69 74 86/ 30%)",
      },
  },
  btnLink: {
    padding: "0",
    textDecoration: "none",
    color: "var(--dark)",
    "&:hover": {
      color: "var(--primary)",
      "& svg": {
        "& > path": {
          fill: "var(--primary)",
        },
      },
    },
    "&:before, &:after": {
      display: "none",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        color: "var(--primary)",
        "& svg": {
          "& > path": {
            fill: "var(--primary)",
          },
        },
      },
  },
  btnLg: {
    padding: "0.985rem 34px",
    borderRadius: "9px",
    fontSize: "15px",
  },
  btnSm: {
    padding: "0.407rem 12px",
    borderRadius: "7px",
    fontSize: "14px",
  },
});
