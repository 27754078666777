import {
  ConnectModal,
  useCurrentAccount,
  useDisconnectWallet,
} from "@mysten/dapp-kit";
import { Button, Tooltip } from "antd";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faThumbsUp,
  faUnlink,
} from "@fortawesome/free-solid-svg-icons";
import { copyContent, toastMessage } from 'utils/helper';
import MonogramWhiteIcon from "assets/images/monogramWhite.svg";
import ButtonComponent from "components/FormsComponents/ButtonComponent";
import "./style.css";

const SuiWalletConnect = ({ identifyTxn, generateJsonContent }: any) => {
  const currentAccount = useCurrentAccount();
  const { mutate: disconnect } = useDisconnectWallet();
  const [showConnectModal, setShowConnectModal] = useState(false);

  const ButtonDisconnect = () => {
    const keys = currentAccount?.address;
    const firstFour = keys?.substring(0, 4);
    const lastFour = keys?.slice(keys?.length - 4);
    const result = firstFour + "..." + lastFour;

    const walletDisconnect = () => {
      disconnect();
      setShowConnectModal(false);
    };

    return (
      <>
        <div className="text-center mb-3">
          <div className='d-flex align-items-center justify-content-center mt-0 mb-2'>
            <h4 className="m-0 pe-2 text-primary">{result}</h4>
            <Tooltip
              placement="top"
              className="cursorPointer"
              title="Copy Address"
            >
              <span onClick={() => copyContent(currentAccount?.address)}>
                <FontAwesomeIcon
                  style={{ width: "15", height: "15" }}
                  className="text-primary"
                  icon={faCopy}
                />
              </span>
            </Tooltip>
          </div>
          <div className="text-center d-flex flex-wrap align-items-center justify-content-center">
            <ButtonComponent
              title="Pay Now"
              extraClass="w-100"
              btnSecondaryFill
              onClick={() => identifyTxn()}
            >
              <FontAwesomeIcon
                style={{ width: "16", height: "16" }}
                className="me-2"
                icon={faThumbsUp}
              />
              Pay Now
            </ButtonComponent>
            <ButtonComponent
              title="Disconnect"
              extraClass="w-100 mt-2"
              btnDangerFill
              onClick={() => walletDisconnect()}
            >
              <FontAwesomeIcon
                style={{ width: "18", height: "18" }}
                className="me-2"
                icon={faUnlink}
              />
              Disconnect
            </ButtonComponent>
          </div>
          <hr className="dashed mt-4 mb-0" />
        </div>
      </>
    );
  };

  const onConnectVerifyContent = () => {
    if (generateJsonContent.amount == "Infinity") {
      toastMessage("Something went wrong! Try after some time!")
      return;
    }
    setShowConnectModal(true);
  }

  const ButtonConnect = () => {
    if (currentAccount) {
      return <ButtonDisconnect />;
    }

    return (
      <>
        <ConnectModal
          trigger={<button disabled={!!currentAccount}></button>}
          open={showConnectModal}
          onOpenChange={(isOpen) => setShowConnectModal(isOpen)}
        />
        <ButtonComponent
          extraClass="svgAnimationYX mb-3 w-100"
          btnSecondaryFill
          onClick={() => onConnectVerifyContent()}
        >
          <img src={MonogramWhiteIcon} width={20} className="me-2" alt="Logo" />
          {`Pay ${generateJsonContent.amount} / Connect Wallet`}
        </ButtonComponent>
        {/* <ButtonComponent
          className="w-100"
          style={{ maxWidth: "260px" }}
          title={"Connect & Pay"}
          onClick={() => setShowConnectModal(true)}
          children={"Connect & Pay"}
          btnPrimary={true}
        /> */}
      </>
    );
  };
  return <ButtonConnect />;
};

export default SuiWalletConnect;
