import React from "react";
import { Skeleton } from "antd";
import { useStyles } from "./style";

const SkeletonComponent = ({
  active,
  size,
  title,
  image,
  htmlFor,
  className,
  extraClass,
  style,
  ...rest
}: any) => {
  const classes = useStyles();
  return <Skeleton.Input className={className} style={style} active />;
};

export default SkeletonComponent;
