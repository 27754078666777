import React, { useEffect } from "react";
// import { SuiClient, getFullnodeUrl } from '@mysten/sui.js/client';
// import { TransactionBlock } from "@mysten/sui.js/transactions";
import { SuiClient, getFullnodeUrl } from '@mysten/sui.js/client';
import { Transaction, coinWithBalance } from '@mysten/sui/transactions';
import { TransactionBlock } from '@mysten/sui.js/transactions';
import {
  useCurrentAccount,
  useSignAndExecuteTransactionBlock,
} from "@mysten/dapp-kit";
import {
  SuiPriceServiceConnection,
  SuiPythClient,
} from '@pythnetwork/pyth-sui-js';
import { toastMessage } from 'utils/helper';
import { isCoinShortType, isCoinType, networkSuiObjectUrl, pythIntegrationPackageId } from 'containers/CheckoutContainer/constant';
import SuiWalletConnect from "components/ConnectToWallet/sui";

const SuiWalletComponent = ({
  manageChainAmount,
  isLoading,
  generateJsonContent,
  setLoading,
  setManageLabel,
  onPaymentSuccess,
}: any) => {
  const { mutate: signAndExecuteTransactionBlock } = useSignAndExecuteTransactionBlock();
  const currentAccount = useCurrentAccount();

  function getProvider(url: string) {
    return new SuiClient({ url });
  }

  // const getObjectId = async (txnValue: any) => {
  //   let res = await fetch(networkSuiObjectUrl, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       jsonrpc: "2.0",
  //       id: 1,
  //       method: "suix_getCoins",
  //       params: [currentAccount?.address, isCoinType],
  //     }),
  //   });
  //   let json = await res.json();
  //   if (json && json.result && json.result.data) {
  //     let isNotValid = 0;
  //     for (var i = 0; i < json.result.data.length; i++) {
  //       if (json.result.data[i].balance > txnValue) {
  //         return json.result.data[i].coinObjectId;
  //       } else {
  //         isNotValid += 1;
  //       }
  //     }
  //     if (isNotValid !== 0) {
  //       toastMessage("OOPS! You dont have sufficient balance");
  //       return 0;
  //     }
  //   }
  // };

  const sendSUITxn = async () => {

    if (generateJsonContent.amount == "Infinity") {
      toastMessage("Something went wrong! Try after some time!")
      return;
    }

    if (!currentAccount) {
      return;
    }

    try {
      const tx = new TransactionBlock();
      const txb = new Transaction();
      const connection = new SuiPriceServiceConnection(
        'https://hermes.pyth.network'
      );
      const priceIDs = [
        '0x23d7315113f5b1d3ba7a83604c44b94d79f4fd69af77f804fc7f920a6dc65744', // SUI/USD price ID
        '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace', // ETH/USD price ID
      ];
      const priceUpdateData = await connection.getPriceFeedsUpdateData(priceIDs);
      const wormholeStateId =
        '0xaeab97f96cf9877fee2883315d459552b2b921edc16d7ceac6eab944dd88919c'; // mainnet
      const pythStateId =
        '0x1f9310238ee9298fb703c3419030b35b22bb1cc37113e3bb5007c99aec79e5b8'; // Mainnet
      const rpcUrl = getFullnodeUrl('mainnet');

      const provider = getProvider(rpcUrl);

      const clientt = new SuiPythClient(provider, pythStateId, wormholeStateId);

      const priceInfoObjectIds = await clientt.updatePriceFeeds(
        tx,
        priceUpdateData,
        priceIDs
      );


      const txMuldyValue = Math.floor(
        (+generateJsonContent?.amount * manageChainAmount.sui) * 1_000_000_000
      );

      const [coins] = tx.splitCoins(tx.gas, [tx.pure(txMuldyValue)]);

      tx.setSender(currentAccount?.address);

      tx.moveCall({
        target: `${pythIntegrationPackageId}::pocketpay::send_tx`,
        typeArguments: [isCoinShortType],
        arguments: [
          coins,
          tx.pure(generateJsonContent.merchant_address),
          tx.pure('PocketPay'),
          tx.pure(generateJsonContent.amount * 1_000_000),
          tx.pure(generateJsonContent.order_id),
          tx.object(pythStateId),
          tx.object(priceInfoObjectIds[0]),
          tx.object('0x6'),
        ],
      });

      const response = await signAndExecuteTransactionBlock(
        {
          transactionBlock: tx as any,
          options: {
            showEffects: true,
            showBalanceChanges: true,
            showEvents: true,
          },
        },
        {
          onSuccess: (result) => {
            if (result && result.digest) {
              const manageDigest = result.digest;
              const address = currentAccount?.address;
              const orderId = generateJsonContent.order_id;
              onPaymentSuccess(
                generateJsonContent,
                manageDigest,
                address,
                orderId
              );
            }
          },
        }
      );
    } catch (error) {
      toastMessage(error)
    }
  };

  const identifyTxn = async () => {
    if (currentAccount !== null && currentAccount !== undefined) {
      // const getObjectkey = await getObjectId(generateJsonContent.amount * 1_000_000_000);
      sendSUITxn();
    }
  };

  useEffect(() => {
    if (isLoading) {
      setManageLabel("Process to connecting...");
    } else if (currentAccount) {
      setManageLabel("Wallet connected, Pay now");
    } else {
      setManageLabel("Connect your wallet to make a payment");
    }
  }, [currentAccount, isLoading]);

  return (
    <SuiWalletConnect
      identifyTxn={identifyTxn}
      generateJsonContent={generateJsonContent}
    />
  );
};

export default SuiWalletComponent;
