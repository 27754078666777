import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  body: {
    paddingTop: "40px",
    backgroundColor: "var(--white)",
    minHeight: "calc(100vh - 101px)",
  },
  mainWraper: {
    position: "relative",
    "& .mwLeft": {
      backgroundColor: "var(--white)",
    },
    "& .mwRight": {
      backgroundColor: "var(--light)",
      borderRadius: "16px",
      display: "block",
      [breakpoints.SCREEN_XL_MIN]: {
        padding: "35px",
      },
      [breakpoints.SCREEN_XL_MAX]: {
        padding: "30px",
      },
      [breakpoints.SCREEN_LG_MAX]: {
        padding: "25px",
      },
      [breakpoints.SCREEN_MD_MAX]: {
        padding: "20px",
      },
      "&:not(:last-child)": {
        marginBottom: "10px",
      },
      "&.first": {
        marginTop: "60px",
        "& .mwRBox": {
          marginTop: "-100px",
        },
      },
      "& .mwRBox": {
        position: "relative",
        "& .mwRPayment": {
          backgroundColor: "rgb(255 255 255 / 80%)",
          boxShadow: "0 10px 30px rgb(0 0 0 / 4%)",
          border: "1px solid var(--ghostwhite)",
          backdropFilter: "blur(30px)",
          position: "relative",
          borderRadius: "16px",
          padding: "20px",
          zIndex: "3",
          [breakpoints.SCREEN_LG_MIN]: {
            padding: "25px",
          },
        },
        "& .blurLine": {
          width: "80px",
          height: "80px",
          backgroundColor: "var(--primary)",
          position: "absolute",
          left: "0",
          right: "0",
          top: "-6px",
          margin: "0 auto",
          zIndex: "1",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        },
        "& .mwRChain": {},
      },
    },
    // payList
    "& .payList": {
      "& .plItems": {
        overflow: "hidden",
        maxWidth: "calc(100% - 1px)",
        textOverflow: "ellipsis",
        "&:not(:last-child)": {
          marginBottom: "8px",
        },
        "& p": {
          fontSize: "15px",
          paddingRight: "8px",
          whiteSpace: "nowrap",
          color: "var(--lightGrey)",
          fontWeight: "var(--fontWeight500)",
        },
        "& h4": {
          fontSize: "16px",
          color: "var(--dark)",
          fontWeight: "var(--fontWeight700)",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          "& > span": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
          "& > svg": {
            flex: "0 0 13px",
          },
        },
      },
    },
    // youPay
    "& .youPay": {
      "& .ypLe": {
        "& p": {
          color: "var(--lightGrey)",
        },
        "& .ypLBo": {
          "& h4": {
            lineHeight: "1",
            fontSize: "28px",
            color: "var(--dark)",
            fontWeight: "var(--fontWeight800)",
          },
          "& p": {
            "& span": {
              color: "var(--darkMore)",
              fontWeight: "var(--fontWeight700)",
            },
          },
        },
      },
      "& .ypRi": {},
    },
    // netCoList
    "& .netCoList": {
      gap: "6px",
      "& .ncItems": {
        backgroundColor: "var(--white)",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#E4E4E4",
        borderRadius: "40px",
        padding: "6px 10px 6px 8px",
        cursor: "pointer",
        "&.selected": {
          backgroundColor: "rgb(8 209 149 / 10%)",
          borderColor: "var(--success)",
          "& .ncIIcn": {
            backgroundColor: "var(--success)",
            borderColor: "var(--success)",
          },
        },
        "& .ncIIcn": {
          width: "24px",
          height: "24px",
          flex: "0 0 24px",
          borderRadius: "50rem",
          backgroundColor: "var(--white)",
        },
        "& .ncIText": {
          fontSize: "14px",
          marginLeft: "6px",
          color: "var(--darkMore)",
          fontWeight: "var(--fontWeight600)",
        },
      },
    },
    // selectedList
    "& .selectedList": {
      "& .slItems": {
        WebkitTransition: "all 0.2s ease-in-out",
        transition: "all 0.2s ease-in-out",
        "&:not(:first-child)": {
          marginLeft: "-10px",
        },
        "&:hover": {
          "& .slIBox": {
            position: "relative",
            zIndex: "2",
          },
        },
        "& .slIBox": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "var(--fontWeight600)",
          boxShadow: "-3px 0 10px rgb(0 0 0 / 0.06%)",
          width: "32px",
          height: "32px",
          borderRadius: "50rem",
          background: "var(--white)",
          border: "1px solid var(--white)",
          WebkitTransition: "all 0.2s ease-in-out",
          transition: "all 0.2s ease-in-out",
          "& .ant-image": {
            position: "unset",
          },
        },
      },
    },
    // copyCode
    "& .copyCode": {
      backgroundColor: "rgb(89 128 220 / 8%)",
      border: "1px solid var(--white)",
      padding: "8px 8px 8px 16px",
      borderRadius: "8px",
      "& .ccIcn": {
        width: "40px",
        height: "40px",
        backgroundColor: "var(--white)",
        borderRadius: "8px",
        WebkitTransition: "all 0.2s ease-in-out",
        transition: "all 0.2s ease-in-out",
        "& svg": {
          "& > path": {
            WebkitTransition: "all 0.2s ease-in-out",
            transition: "all 0.2s ease-in-out",
          },
        },
        "&:hover": {
          backgroundColor: "var(--primary)",
          "& svg": {
            "& > path": {
              fill: "var(--white)",
            },
          },
        },
      },
      "& .h5": {
        color: "var(--darkMore)",
        fontWeight: "var(--fontWeight600)",
      },
    },
  },
});
