import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import React from "react";

const SolanaWalletComponent = ({
  isLoading,
  generateJsonContent,
  setLoading,
  setManageLabel,
  onPaymentSuccess,
}: any) => {
  return (
    <WalletMultiButton className="btn bg-[#231d48] hover:bg-[#231d48] text-black font-bold py-2 px-4 rounded" />
  );
};

export default SolanaWalletComponent;
