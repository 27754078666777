import React from "react";
import classnames from "classnames";
import { useStyles } from "./style";

const ButtonComponent = ({
  children,
  onClick,
  className,
  disabled,
  type,
  title,
  bgColor,
  color,
  btnLink,
  btnPrimary,
  btnPrimaryFill,
  btnSecondary,
  btnSecondaryFill,
  btnDangerFill,
  btnLg,
  btnSm,
  extraClass,
  style,
  preFixIcon,
  postFixIcon,
  peNone,
  buttonRef,
  theme,
  loading,
  ...rest
}: any) => {
  const classes = useStyles();
  return (
    <button
      disabled={loading ? loading : disabled}
      title={title}
      type={type}
      ref={buttonRef}
      className={classnames(
        classes.btn,
        className,
        extraClass,
        btnLink && classes.btnLink,
        btnPrimary && classes.btnPrimary,
        btnPrimaryFill && classes.btnPrimaryFill,
        btnSecondary && classes.btnSecondary,
        btnSecondaryFill && classes.btnSecondaryFill,
        btnDangerFill && classes.btnDangerFill,
        btnLg && classes.btnLg,
        btnSm && classes.btnSm
      )}
      onClick={onClick}
      style={style}
      {...rest}
    >
      {preFixIcon}
      {children}
      {postFixIcon}
    </button>
  );
};

export default ButtonComponent;
