import { Row, Col } from "antd";
import React, { useEffect } from "react";
import { useStyles } from "./style";
import classNames from "classnames";

// Define the type of configuration explicitly
type WidgetConfig = {
  v: string;
  element: string;
  title: string;
  description: string;
  width: string;
  height: string;
  r: null;
  supportedChains: string;
  inputChain: number;
  outputChain: number;
  inputCurrency: string;
  outputCurrency: string;
  address: string;
  showSwapTransfer: boolean;
  amount: string;
  outputAmount: string;
  isAmountFromNotModifiable: boolean;
  isAmountToNotModifiable: boolean;
  lang: string;
  mode: string;
  isEnableCalldata: boolean;
  styles: string;
  theme: string;
  isHideLogo: boolean;
  logo: string;
};

// Place this declaration somewhere in your TypeScript files (e.g., a custom.d.ts file or at the top of your component file)
declare global {
  interface Window {
    deBridge: {
      widget: (config: any) => void;
    };
  }
}

const DebridgeWidget: React.FC = () => {
  const classes = useStyles();
  const config: WidgetConfig = {
    v: "1",
    element: "debridgeWidget",
    title: "",
    description: "",
    width: "600",
    height: "550",
    r: null,
    supportedChains:
      '{"inputChains":{"1":"all","10":"all","56":"all","137":"all","8453":"all","42161":"all","43114":"all","59144":"all","7565164":"all","245022934":"all"},"outputChains":{"1":"all","10":"all","56":"all","137":"all","8453":"all","42161":"all","43114":"all","59144":"all","7565164":"all","245022934":"all"}}',
    inputChain: 56,
    outputChain: 1,
    inputCurrency: "",
    outputCurrency: "",
    address: "",
    showSwapTransfer: true,
    amount: "",
    outputAmount: "",
    isAmountFromNotModifiable: false,
    isAmountToNotModifiable: false,
    lang: "en",
    mode: "deswap",
    isEnableCalldata: false,
    styles:
      "eyJib3JkZXJSYWRpdXMiOjgsImZvbnRGYW1pbHkiOiIiLCJwcmltYXJ5QnRuQmciOiIiLCJzZWNvbmRhcnlCdG5CZyI6IiIsImxpZ2h0QnRuQmciOiIifQ==",
    theme: "dark",
    isHideLogo: false,
    logo: "",
  };
  useEffect(() => {
    // Function to dynamically load the deBridge script
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = "https://app.debridge.finance/assets/scripts/widget.js";
      script.onload = () => {
        // Initialize the widget once the script is loaded
        if (window.deBridge) {
          window.deBridge.widget({
            v: "1",
            element: "debridgeWidget",
            title: "",
            description: "",
            width: "600",
            height: "550",
            r: null,
            supportedChains:
              '{"inputChains":{"1":"all","10":"all","56":"all","137":"all","8453":"all","42161":"all","43114":"all","59144":"all","7565164":"all","245022934":"all"},"outputChains":{"1":"all","10":"all","56":"all","137":"all","8453":"all","42161":"all","43114":"all","59144":"all","7565164":"all","245022934":"all"}}',
            inputChain: 56,
            outputChain: 1,
            inputCurrency: "",
            outputCurrency: "",
            address: "",
            showSwapTransfer: true,
            amount: "",
            outputAmount: "",
            isAmountFromNotModifiable: false,
            isAmountToNotModifiable: false,
            lang: "en",
            mode: "deswap",
            isEnableCalldata: false,
            styles:
              "eyJib3JkZXJSYWRpdXMiOjgsImZvbnRGYW1pbHkiOiIiLCJwcmltYXJ5QnRuQmciOiIiLCJzZWNvbmRhcnlCdG5CZyI6IiIsImxpZ2h0QnRuQmciOiIifQ==",
            theme: "dark",
            isHideLogo: false,
            logo: "",
          });
        }
      };
      document.body.appendChild(script);
    };

    // Only add the script if it's not already loaded
    if (!window.deBridge) {
      loadScript();
    } else {
      // Initialize the widget directly if script is already there
      window.deBridge.widget(config);
    }
  }, []);

  return (
    <div className={classNames(classes.body, "bodyCommon")}>
      <div className="container">
        <div className={classNames(classes.mainWraper)}>
          <div id="debridgeWidget"></div>
        </div>
      </div>
    </div>
  );
};

export default DebridgeWidget;
