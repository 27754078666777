import React, { useEffect, useState } from "react";
import { Col, Row, Image, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ToastContainer } from "react-toastify";
import { baseUrl } from "utils/env";
import { decodeContent, getCoinAmount } from "utils/helper";
import ContentComponent from "../ContentComponent";
import SuccessComponent from "../SuccessComponent";

const CheckoutComponent = () => {
  const [transactionContent, setTransactionContent] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [manageDigest, setManageDigest] = useState("");
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  // const [showIsSuccess, setShowIsSuccess] = useState(false);
  // const [showQr, setShowQr] = useState(false);
  // const [storedId, setStoreId] = useState("");
  const [manageChainAmount, setManageChainAmount] = useState({
    sui: 0,
    solana: 0,
    blast: 0,
    bnb: 0,
    polygon: 0,
    base: 0,
    linea: 0,
    taiko: 0,
    usdt: 0,
    pyth: 0,
    bonk: 0,
    wen: 0,
    jup: 0,
  });

  useEffect(() => {
    getCoinAmount(setManageChainAmount);
  }, []);

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const pathname = window.location.pathname;
        const modifiedInput = pathname.replace("/checkout/", "");
        const decryptValue = decodeContent(modifiedInput);
        if (decryptValue) {
          try {
            const response = await fetch(`${baseUrl}/linkpay/${decryptValue}`);
            if (!response.ok) {
              throw new Error(`Error: ${response.status}`);
            }
            const result = await response.json();
            if (result && result.status === true) {
              const { data } = result;
              if (data.status === "pending") {
                setTransactionContent(data);
              } else {
                setTransactionContent(data);
                setManageDigest(data.signature);
                setIsPaymentSuccess(true);
              }
            }
          } catch (err: any) {
            setError(err.message);
          } finally {
            setLoading(false);
          }
        }
      } catch (err: any) {
        setError(err.message);
      }
    };

    fetchTransaction();
  }, []);

  if (loading)
    return (
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="my-3 my-md-4 my-lg-5">
      <ToastContainer />
      {Object.keys(transactionContent).length !== 0 && (
        <div className="container">
          {isPaymentSuccess && manageDigest !== "" ? (
            <SuccessComponent
              generateJsonContent={transactionContent}
              manageDigest={manageDigest}
            />
          ) : (
            <ContentComponent
              transactionContent={transactionContent}
              manageChainAmount={manageChainAmount}
              setManageDigest={setManageDigest}
              setIsPaymentSuccess={setIsPaymentSuccess}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CheckoutComponent;
