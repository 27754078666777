import React from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";

interface ConnectButtonProps {
  label: any; // or JSX.Element
}

const ConnectButtonEVMChain: React.FC<ConnectButtonProps> = ({ label }) => {
  return <ConnectButton label={label} />;
};

export default ConnectButtonEVMChain;
