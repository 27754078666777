import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import classNames from "classnames";
import { SuccessLoader } from "svgIcon";
import { baseUrl } from "utils/env";
import { checkCoin, decodeContent, shortenAddress } from "utils/helper";
import { useStyles } from "./style";

function SuccessComponent({ generateJsonContent, manageDigest }: any) {
  const classes = useStyles();
  const [url, setUrl] = useState("");
  const onRenderAddress = async (getDigest: any) => {
    const renderAddress = await checkCoin(
      generateJsonContent.blockchain,
      getDigest
    );
    if (renderAddress) {
      setUrl(renderAddress);
    }
  };
  const onSuccessAPI = () => {
    const pathname = window.location.pathname;
    const modifiedInput = pathname.replace("/checkout/", "");
    const decryptValue = decodeContent(modifiedInput);
    if (decryptValue) {
      fetch(`${baseUrl}/linkpay`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: decryptValue,
          status: "completed",
          signature: manageDigest,
        }),
      }).then((response) => {
        response.json();
      });
    }
  };
  useEffect(() => {
    onSuccessAPI();
    onRenderAddress(manageDigest);
  }, []);
  return (
    <Row gutter={{ xs: 10, sm: 10, md: 14, lg: 20 }} justify="center">
      <Col
        span={9}
        xs={{ span: 24 }}
        sm={{ span: 22 }}
        md={{ span: 20 }}
        lg={{ span: 12 }}
        xl={{ span: 9 }}
      >
        <div className={classNames(classes.merCard, "merCardCommon")}>
          <div className={classNames(classes.merCardImage)}>
            <SuccessLoader />
          </div>
          <h3 className="mb-4">
            Your order has been placed <br />
            successfully
          </h3>
          <p>
            View on explorer:{" "}
            <span
              className={classNames(classes.merCardLink)}
              onClick={() => window.open(url, "_blank")}
            >
              {shortenAddress(manageDigest)}
            </span>
          </p>
        </div>
      </Col>
    </Row>
  );
}
export default SuccessComponent;
