import React from "react";
import prototype from "prop-types";
import classNames from "classnames";
import LabelComponent from "../LabelComponent";
import { useStyles } from "./style";

const TextBoxComponent = ({
  error = "",
  label = "",
  subTitle = "",
  mandatory,
  title = "Textbox",
  className,
  extraClass,
  labelExtraIcon,
  loading,
  style,
  theme,
  ...rest
}: any) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.frGrp, extraClass, "frGrpCommon")}
      style={style}
    >
      <div className="frgInner d-flex align-items-end">
        {label && (
          <LabelComponent>
            {label} {mandatory && <span className="text-danger">*</span>}
            {labelExtraIcon}
          </LabelComponent>
        )}
        {subTitle && <small>{subTitle}</small>}
        <input
          maxLength={250}
          aria-label={title ? title : label}
          aria-labelledby={title ? title : label}
          title={title ? title : label}
          className={classNames(className)}
          autoComplete="off"
          {...rest}
        />
      </div>
      {error && (
        <span
          className={classNames(classes.error, "text-danger d-block text-end")}
        >
          {error}
        </span>
      )}
    </div>
  );
};
export default TextBoxComponent;
