import React from "react";
import {
  createNetworkConfig,
  SuiClientProvider,
  WalletProvider,
} from "@mysten/dapp-kit";
import "@mysten/dapp-kit/dist/index.css";
import { getFullnodeUrl, type SuiClientOptions } from "@mysten/sui.js/client";
import { GlobalDisplayProvider, GlobalMerchantProvider } from "utils/helper";
import BodyComponent from "components/Layout/BodyComponent";
import SolanaProivder from "provider/SolanaProvider";
import "assets/common.css";
import "./App.css";

const { networkConfig } = createNetworkConfig({
  testnet: { url: getFullnodeUrl("testnet") },
  mainnet: { url: getFullnodeUrl("mainnet") },
});

function App() {
  return (
    <SolanaProivder>
      <SuiClientProvider networks={networkConfig} defaultNetwork="testnet">
        <WalletProvider>
          <GlobalMerchantProvider>
            <GlobalDisplayProvider>
              <BodyComponent />
            </GlobalDisplayProvider>
          </GlobalMerchantProvider>
        </WalletProvider>
      </SuiClientProvider>
    </SolanaProivder>
  );
}

export default App;
