import React from "react";
import classNames from "classnames";
import LabelComponent from "../LabelComponent";
import { useStyles } from "./style";

const TextAreaComponent = ({
  error = "",
  label = "",
  subTitle = "",
  mandatory,
  title = "Textarea",
  className,
  extraClass,
  rightTopIcon,
  labelExtraIcon,
  style,
  theme,
  note,
  ...rest
}: any) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.frGrp, extraClass, "frGrpCommon")}
      style={style}
    >
      {label && (
        <LabelComponent>
          {label} {mandatory && <span className="mandatory">*</span>}
          {labelExtraIcon}
        </LabelComponent>
      )}
      {subTitle && <small>{subTitle}</small>}
      <textarea
        maxLength={5000}
        aria-label={title ? title : label}
        aria-labelledby={title ? title : label}
        title={title ? title : label}
        className={classNames(className, "px-0")}
        {...rest}
      ></textarea>
      {note && <span>{note}</span>}
      {error && <span className="text-danger d-block text-end">{error}</span>}
    </div>
  );
};
export default TextAreaComponent;
