import React, { useState } from "react";
import classNames from "classnames";
import { PendingLoader } from "svgIcon";
import SolanaWalletComponent from "../SolanaWalletComponent";
import SuiWalletComponent from "../SuiWalletComponent";
import BlastWalletComponent from "../BlastWalletComponent";
import { useStyles } from "./style";

const PaymentWalletComponent = ({
  setManageDigest,
  generateJsonContent,
  setIsPaymentSuccess,
  manageChainAmount
}: any) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [manageLabel, setManageLabel] = useState("");

  const onPaymentSuccess = (
    content: any,
    digest: any,
    sender: any,
    getKey: any
  ) => {
    setManageDigest(digest);
    setLoading(false);
    setIsPaymentSuccess(true);
  };

  const renderChainComponent = (getChainName: any) => {
    switch (getChainName) {
      case "SUI": {
        return (
          <SuiWalletComponent
            manageChainAmount={manageChainAmount}
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "Blast": {
        return (
          <BlastWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "LINEA": {
        return (
          <BlastWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "BASE": {
        return (
          <BlastWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "TAIKO": {
        return (
          <BlastWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "Polygon": {
        return (
          <BlastWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "BNB": {
        return (
          <BlastWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "SOL": {
        return (
          <SolanaWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "Solana": {
        return (
          <SolanaWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "SOL-USDC": {
        return (
          <SolanaWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "USDT": {
        return (
          <SolanaWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "PYTH": {
        return (
          <SolanaWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "BONK": {
        return (
          <SolanaWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "WEN": {
        return (
          <SolanaWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "JUP": {
        return (
          <SolanaWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
    }
  };

  return (
    <div className={classNames(classes.conWallet, "text-center")}>
      {isLoading && (
        <div className="py-5">
          <div className="d-flex justify-content-center">
            <PendingLoader />
          </div>
          <h4 className="mt-2">Process to connecting...</h4>
        </div>
      )}
      {!isLoading && (
        <div className="conBtn">
          {renderChainComponent(generateJsonContent.blockchain)}
        </div>
      )}
    </div>
  );
};

export default PaymentWalletComponent;
