export const requiredField = {
  link_name: "",
  amount: "",
  sender_email: "",
  sender_address: "",
  receiver_email: "",
  receiver_address: "",
  message: "",
  // schainlist: [],
  schainlist: "",
  stokenlist: [],
};
export const requiredMandatoryField = {
  link_name: "",
  amount: "",
  sender_email: "",
  receiver_email: "",
  receiver_address: "",
  message: "",
  stokenlist: [],
};
// export const chainlist = [
//   "Solana",
//   "SUI",
//   "Blast",
//   "Polygon",
//   "BNB",
//   "LINEA",
//   "BASE",
//   "TAIKO",
// ];
export const chainlist = [
  "Solana",
  "SUI",
];
export const tokenlist = [
  {
    key: "Solana",
    coin: ["SOL-USDC", "USDT", "SOL", "PYTH", "BONK", "JUP", "WEN"],
  },
  {
    key: "SUI",
    coin: ["SUI"],
  },
  {
    key: "Blast",
    coin: ["ETH"],
  },
  {
    key: "Polygon",
    coin: ["MATIC"],
  },
  {
    key: "BNB",
    coin: ["BNB"],
  },
  {
    key: "LINEA",
    coin: ["ETH"],
  },
  {
    key: "BASE",
    coin: ["ETH"],
  },
  {
    key: "TAIKO",
    coin: ["ETH"],
  },
];
// export const tokenlist = [
//   "SOL-USDC",
//   "USDT",
//   "SOL",
//   "PYTH",
//   "BONK",
//   "JUP",
//   "WEN",
// ];
