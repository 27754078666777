import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  body: {
    paddingTop: "40px",
    backgroundColor: "var(--white)",
    minHeight: "calc(100vh - 101px)",
  },
  mainWraper: {
    position: "relative",
    "& iframe": {
      margin: "0 auto",
      borderRadius: "30px",
    },
  },
});
