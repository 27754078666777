import classNames from "classnames";
import { useStyles } from "components/Layout/FooterComponent/style";
const FooterComponent = () => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.footer, "footerCommon")}>
      <div className="container">
        <p className="m-0 text-center">
          Powered by <strong>PocketPay Finance</strong>
        </p>
      </div>
    </div>
  );
};

export default FooterComponent;
