import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  footer: {
    position: "relative",
    zIndex: "2",
    paddingTop: "10px",
    paddingBottom: "9px",
    backgroundColor: "var(--white)",
    "& p": {
      fontSize: "14px",
    },
  },
});
