export const selectedChainlist = ["Solana", "SUI"];
export const selectedTokenlist = [
  "SOL-USDC",
  "USDT",
  "SOL",
  "PYTH",
  "BONK",
  "JUP",
  "WEN",
];

export const endOfTime = 45;
export const isClientName = "pocketpay.sui";
export const isClientChain = "sui";
export const isMerchantName = "PocketPay Finance";
export const isCoinName = "SUI";
export const isClientNetwork = "Mainnet";
export const isCoinShortType = "0x2::sui::SUI";
export const networkUrl = "https://wallet-rpc.mainnet.sui.io/";
export const networkSuiObjectUrl = "https://fullnode.mainnet.sui.io:443";
export const isClientAddress =
  "0x3121399d6ca64e08428d7e0010ac23c269ec379ac6f7fa411cb1c994c11b6f63";
export const packageId =
  "0xa5ce1125c7b15d3abf7cba02084c9bd013a4dc4296611ee0922e6e2355846a37";
export const isCoinType =
  "0x0000000000000000000000000000000000000000000000000000000000000002::sui::SUI";
export const pythIntegrationPackageId = "0xc5bd9cd48130aefe3fcd9dee5bcd457fe9e96c3722abf4fe49e5848fb8a3d0d1";