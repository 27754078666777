import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .hLinks": {
      display: "inline-flex",
      alignItems: "center",
      "& .hlItems": {
        cursor: "pointer",
        fontSize: "15px",
        padding: "12px 5px",
        color: "var(--dark)",
        fontWeight: "var(--fontWeight700)",
        borderWidth: "3px",
        borderStyle: "solid",
        borderColor: "transparent",
        display: "inline-flex",
        alignItems: "center",
        height: "60px",
        "&:not(:last-child)": {
          marginRight: "30px",
        },
        "&.active": {
          borderTopColor: "var(--primary)",
        },
      },
    },
  },
  header: {
    borderBottom: "1px solid #F1F1F4",
    backgroundColor: "#fcfcfc",
    "& .hLogo": {
      display: "flex",
      alignItems: "center",
      paddingTop: "14px",
      paddingBottom: "13px",
    },
  },
  noneCartIcn: {
    display: "none",
  },
  removeCartIcn: {
    display: "contents",
    "& svg": {
      width: "22px",
      height: "22px",
      "& > path": {
        fill: "var(--primary)",
      },
    },
  },
  addCartIcn: {
    display: "none",
    "& svg": {
      width: "22px",
      height: "22px",
      "& > path": {
        fill: "var(--primary)",
      },
    },
  },
});
