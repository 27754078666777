import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Col, Row } from "antd";
import LinkFormComponent from "../LinkFormComponent";
import LinkProceedComponent from "../LinkProceedComponent";
import LinkSharedComponent from "../LinkSharedComponent";
// import { useStyles } from "components/PaymentLink/PaymentLinkComponent/style";
import { requiredField } from "containers/PaymentLinkContainer/constant";
import { useStyles } from "./style";

const PaymentLinkComponent = () => {
  const classes = useStyles();
  const [errors, setErrors] = useState<any>();
  const [generateLink, setGenerateLink] = useState("");
  const [formData, setFormData] = useState({
    ...requiredField,
  });
  return (
    <div className={classNames(classes.body, "bodyCommon")}>
      <div className="container">
        <div className={classNames(classes.mainWraper)}>
          <Row gutter={{ xs: 20, sm: 20, md: 30, lg: 40 }}>
            <Col
              className="gutter-row mb-5 mb-lg-0"
              span={15}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 14 }}
              xl={{ span: 15 }}
            >
              <div className="mwLeft">
                <LinkFormComponent
                  formData={formData}
                  setFormData={setFormData}
                  errors={errors}
                  setErrors={setErrors}
                />
              </div>
            </Col>
            <Col
              className="gutter-row"
              span={9}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 10 }}
              xl={{ span: 9 }}
            >
              <div className="mwRight first">
                <div className="mwRBox">
                  <LinkProceedComponent
                    formData={formData}
                    setFormData={setFormData}
                    setGenerateLink={setGenerateLink}
                    errors={errors}
                    setErrors={setErrors}
                  />
                </div>
              </div>
              {generateLink !== "" && (
                <div className="mwRight">
                  <LinkSharedComponent generateLink={generateLink} />
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PaymentLinkComponent;
