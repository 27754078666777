import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import BigNumber from "bignumber.js";
import { QRCodeCanvas } from "qrcode.react";
import { Col, Row, Tooltip, Image } from "antd";
import { PublicKey, Keypair, Connection } from "@solana/web3.js";
import {
  findReference,
  validateTransfer,
  encodeURL,
  createQR,
} from "@solana/pay";
import {
  ChainSOL,
  ChainSUI,
  ChainSolanaBONK,
  ChainSolanaJUP,
  ChainSolanaPYTH,
  ChainSolanaUSDC,
  ChainSolanaUSDT,
  ChainSolanaWEN,
  identifyCoinNetwork,
  solTokenAddresses,
  toastMessage,
} from "utils/helper";
import { AppleIcon, PlayStoreIcon } from "svgIcon";
import { networkUrl, packageId } from "containers/CheckoutContainer/constant";
import PaymentCouldntFetchComponent from "../PaymentCouldntFetchComponent";
import PaymentVerifyBoxComponent from "../PaymentVerifyBoxComponent";
import { useStyles } from "components/PaymentOptions/PayWithQR/PaymentQRComponent/style";

const PaymentQRComponent = ({
  amount,
  setManageDigest,
  generateJsonContent,
  setIsPaymentSuccess,
  setShowQR,
}: any) => {
  const classes = useStyles();
  const [qrStatus, setQrStatus] = useState(0);
  const [qrKey, setQrKey] = useState({});
  const [qrString, setQrString] = useState("");
  const [showTiming, setShowTiming] = useState(90);
  const refIsReloadInProgress = useRef(false);
  const [showTxnInput, setShowTxnInput] = useState(false);
  const [qrCodeURL, setQrCodeURL] = useState("");

  const subSOLCoinNames = [
    ChainSolanaUSDC,
    ChainSolanaUSDT,
    ChainSolanaPYTH,
    ChainSolanaBONK,
    ChainSolanaWEN,
    ChainSolanaJUP,
  ];
  const isItSolSubTokens = subSOLCoinNames.includes(
    generateJsonContent.blockchain
  );

  const onPaymentSuccess = (
    content: any,
    digest: any,
    sender: any,
    getKey: any
  ) => {
    setManageDigest(digest);
    setIsPaymentSuccess(true);
    setShowQR(false);
  };

  let checkData = 1;
  let timeCheck = 90;

  const onfetchLastSuiTransaction = (content: any, randomKey: any) => {
    if (refIsReloadInProgress.current) {
      return;
    }
    refIsReloadInProgress.current = true;

    const data = {
      method: "suix_queryTransactionBlocks",
      jsonrpc: "2.0",
      params: [
        {
          filter: {
            InputObject: packageId,
          },
          options: {
            showEffects: true,
            showInput: true,
          },
        },
        null,
        1,
        true,
      ],
      id: "1",
    };

    try {
      axios
        .post(networkUrl, data)
        .then(({ data }) => {
          const { result } = data;
          if (result) {
            const { data } = result;
            if (
              data &&
              data[0] &&
              data[0].transaction &&
              data[0].transaction.data.transaction.inputs.length === 5 &&
              data[0].transaction.data.transaction.inputs[1].value ===
              randomKey.toString() &&
              data[0].transaction.data.transaction.inputs[4].value ==
              content.amount * 1000000000 &&
              data[0].transaction.data.transaction.inputs[3].value ==
              content.merchant_address
            ) {
              if (
                data &&
                data[0] &&
                data[0].effects &&
                data[0].effects.status &&
                data[0].effects.status.status == "success"
              ) {
                onPaymentSuccess(
                  content,
                  data[0].digest,
                  data[0].transaction.data.sender,
                  randomKey
                );
                checkData += 1;
              } else {
                setQrStatus(3);
              }
            } else {
              if (checkData == 1 && timeCheck !== 0) {
                timeCheck -= 1;
                setShowTiming(timeCheck);
                setTimeout(() => {
                  onfetchLastSuiTransaction(content, randomKey);
                }, 1500);
              } else {
                setQrStatus(4);
              }
            }
          }
        })
        .catch((error: any) => {
          console.log("OOPS! The SUI network may be down");
        });
    } catch {
      console.log("OOPS! The SUI network may be down");
    }
    refIsReloadInProgress.current = false;
  };

  const onfetchSolanaTransaction = async (
    content: any,
    randomKey: any,
    newReference: any
  ) => {
    try {
      const SolRPC =
        "https://mainnet.helius-rpc.com/?api-key=af28767a-214e-498f-9b5a-67c30bb6ed81";
      const connection = new Connection(SolRPC, "confirmed");

      if (!newReference) {
        toastMessage("No reference found.");
        return;
      }

      let tokenAddress;
      if (isItSolSubTokens) {
        const usdcTokenAddress = solTokenAddresses(
          generateJsonContent.blockchain
        );
        if (usdcTokenAddress) {
          tokenAddress = new PublicKey(usdcTokenAddress);
        }
      }
      const splToken = tokenAddress !== null ? tokenAddress : undefined;

      const signatureInfo = await findReference(connection, newReference);
      const isValid = await validateTransfer(
        connection,
        signatureInfo.signature,
        {
          recipient: new PublicKey(content.merchant_address),
          amount: new BigNumber(content.amount),
          // splToken: new PublicKey(splToken as any),
          splToken: splToken,
        }
      );

      if (
        isValid &&
        isValid.transaction &&
        isValid.transaction.message &&
        isValid.transaction.message.accountKeys[0]
      ) {
        const getSender = isValid.transaction.message.accountKeys[0].toBase58();
        toastMessage("Payment validated successfully!");
        onPaymentSuccess(
          content,
          signatureInfo.signature,
          getSender,
          randomKey
        );
        checkData += 1;
      }
    } catch (error) {
      if (checkData == 1 && timeCheck !== 0) {
        timeCheck -= 1;
        setShowTiming(timeCheck);
        setTimeout(() => {
          onfetchSolanaTransaction(content, randomKey, newReference);
        }, 1500);
      } else {
        setQrStatus(4);
      }
    }
  };

  const generateQRCodeAndSetState = async (
    generateJsonContent: any,
    splToken: any,
    newReference: any
  ) => {
    const recipientPublicKey = new PublicKey(
      generateJsonContent.merchant_address
    );
    const newSolanaQrPaymentObject = encodeURL({
      recipient: recipientPublicKey,
      amount: new BigNumber(generateJsonContent.amount),
      splToken:
        splToken !== undefined ? new PublicKey(splToken as any) : splToken,
      reference: newReference,
      label: generateJsonContent.merchant_name,
      message: "Thanks for paying using PocketPay!",
      memo: `${generateJsonContent.order_id}`,
    });

    const qr = createQR(newSolanaQrPaymentObject);
    const qrBlob = await qr.getRawData("png");

    if (!qrBlob) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      if (typeof event.target?.result === "string") {
        setQrCodeURL(event.target.result);
      }
    };
    reader.readAsDataURL(qrBlob);

    const commerceString = JSON.stringify(generateJsonContent);
    setQrKey(generateJsonContent);
    setQrString(commerceString);
  };

  const onLoadGenerateContent = async (newReference: any) => {
    if (generateJsonContent.blockchain === ChainSOL) {
      await generateQRCodeAndSetState(
        generateJsonContent,
        undefined,
        newReference
      );
      return;
    }
    if (isItSolSubTokens) {
      const usdcTokenAddress = solTokenAddresses(
        generateJsonContent.blockchain
      );

      if (usdcTokenAddress) {
        await generateQRCodeAndSetState(
          generateJsonContent,
          usdcTokenAddress,
          newReference
        );
        return;
      }
    }
    const commerceString = JSON.stringify(generateJsonContent);
    setQrKey(generateJsonContent);
    setQrString(commerceString);
  };

  const onListenContent = async (getChainName: any, newReference: any) => {
    if (getChainName === ChainSUI) {
      onfetchLastSuiTransaction(
        generateJsonContent,
        generateJsonContent.order_id
      );
    } else if (getChainName === ChainSOL || isItSolSubTokens) {
      onfetchSolanaTransaction(
        generateJsonContent,
        generateJsonContent.order_id,
        newReference
      );
    }
  };

  const onLoadGenerateQr = async () => {
    setQrStatus(1);
    setShowTiming(90);
    setQrString("");

    const newReference = new Keypair().publicKey;
    onLoadGenerateContent(newReference);
    onListenContent(generateJsonContent.blockchain, newReference);
  };

  const SolanaCoinReturn = () => {
    return (
      <>
        <div className="clItems">
          <p className="m-0 text-dark fontWeight600">
            Scan QR with PocketPay Wallet
          </p>
          <div className="clIApp d-flex flex-wrap align-items-center mt-1">
            <div className="me-2 d-flex">
              <Tooltip
                placement="top"
                className="cursorPointer"
                title="App Store"
              >
                <span className="d-flex mb-1">
                  <AppleIcon className="cursorPointer" />
                </span>
              </Tooltip>
            </div>
            <div className="d-flex">
              <Tooltip
                placement="top"
                className="cursorPointer"
                title="Play Store"
              >
                <span className="d-flex mb-1">
                  <PlayStoreIcon className="cursorPointer" />
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SuiCoinReturn = () => {
    return (
      <>
        <div className="clItems">
          <p className="m-0 text-dark fontWeight600">
            Scan QR with PocketPay Wallet
          </p>
          <div className="clIApp d-flex flex-wrap align-items-center mt-1">
            <div className="me-2 d-flex">
              <Tooltip
                placement="top"
                className="cursorPointer"
                title="App Store"
              >
                <span className="d-flex mb-1">
                  <AppleIcon className="cursorPointer" />
                </span>
              </Tooltip>
            </div>
            <div className="d-flex">
              <Tooltip
                placement="top"
                className="cursorPointer"
                title="Play Store"
              >
                <span className="d-flex mb-1">
                  <PlayStoreIcon className="cursorPointer" />
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderLinkContent = (getChainName: any) => {
    switch (getChainName) {
      case "SUI": {
        return <SuiCoinReturn />;
      }
      case "Solana": {
        return <SolanaCoinReturn />;
      }
      case "SOL": {
        return <SolanaCoinReturn />;
      }
      case "SOL-USDC": {
        return <SolanaCoinReturn />;
      }
      case "USDT": {
        return <SolanaCoinReturn />;
      }
      case "JUP": {
        return <SolanaCoinReturn />;
      }
      case "BONK": {
        return <SolanaCoinReturn />;
      }
      case "WEN": {
        return <SolanaCoinReturn />;
      }
      case "PYTH": {
        return <SolanaCoinReturn />;
      }
    }
  };

  useEffect(() => {
    onLoadGenerateQr();
  }, []);

  return (
    <>
      <div className={classNames(classes.payQr, "my-2")}>
        {qrString !== "" && (
          <>
            <h4 className="mt-0 mb-2 fontWeight700 px-md-5 text-dark text-center">
              Pay using SolanaPay compatible wallet
            </h4>
            {qrStatus === 4 ? (
              <PaymentCouldntFetchComponent
                qrKey={qrKey}
                onLoadGenerateQr={onLoadGenerateQr}
                setShowTxnInput={setShowTxnInput}
              />
            ) : (
              <>
                {generateJsonContent.blockchain === ChainSOL ||
                  isItSolSubTokens ? (
                  <Image
                    src={qrCodeURL}
                    title="Scan to pay"
                    alt="Scan to pay"
                    width={260}
                    height={260}
                    preview={false}
                  />
                ) : (
                  <QRCodeCanvas
                    className="ml-auto mr-auto"
                    title="Scan to pay"
                    value={qrString}
                    width={260}
                    height={260}
                    size={260}
                  />
                )}
              </>
            )}
            <p className="mb-0 mt-1">QR Expires in {showTiming} seconds</p>
            <div
              className={classNames(classes.cainList, "justify-content-center")}
            >
              <div className="clItems mt-4 bg-ghostwhite p-3 rounded">
                <p className="m-0 text-dark fontWeight600">Payable Amount</p>
                <h4 className="m-0 fontWeight800">
                  {Object.keys(generateJsonContent).length > 0 && (
                    <span
                      className="text-primary fontWeight700 mx-1"
                      style={{ position: "relative", top: "1px" }}
                    >{`${amount} ${generateJsonContent?.blockchain}`}</span>
                  )}
                </h4>
              </div>
              {/* {renderLinkContent(generateJsonContent.blockchain)} */}
            </div>
          </>
        )}
        <>
          {qrStatus !== 1 &&
            showTxnInput &&
            generateJsonContent.blockchain === ChainSUI && (
              <PaymentVerifyBoxComponent
                onPaymentSuccess={onPaymentSuccess}
                qrKey={qrKey}
                setQrStatus={setQrStatus}
                setManageDigest={setManageDigest}
              />
            )}
        </>
      </div>
    </>
  );
};

export default PaymentQRComponent;
