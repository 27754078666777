import BridgeComponent from "components/BridgeComponent";
import FooterComponent from "components/Layout/FooterComponent";
import HeaderComponent from "components/Layout/HeaderComponent";
import React, { useEffect } from "react";

const BridgeContainer = () => {
  return (
    <>
      <HeaderComponent />
      <BridgeComponent />
      <FooterComponent />
    </>
  );
};

export default BridgeContainer;
