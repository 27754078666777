import React from "react";
import ButtonComponent from "components/FormsComponents/ButtonComponent";
import { BtnSVGIcn, CopySVGIcn, SendSVGIcn, ShareSVGIcn } from "svgIcon";
import { copyContent, shortenAddress } from "utils/helper";

const LinkSharedComponent = ({ generateLink }: any) => {
  return (
    <div className="mwRBox">
      <div className="copyCode d-flex align-items-center justify-content-between mb-3">
        <span>{generateLink}</span>
        <span
          className="ccIcn cursorPointer d-inline-flex align-items-center justify-content-center"
          onClick={() => copyContent(generateLink)}
          title="Copy"
        >
          <CopySVGIcn style={{ width: "14", height: "16" }} />
        </span>
      </div>
      <div className="d-flex align-items-center">
        <ButtonComponent
          extraClass="me-1 w-100"
          title="Share Link"
          btnPrimaryFill
        >
          <ShareSVGIcn className="aniArrow mr-2" />
          Share Link
        </ButtonComponent>
        <ButtonComponent extraClass="ms-1 w-100" title="Send Email" btnPrimary>
          <SendSVGIcn className="aniArrow mr-2" />
          Send Email
        </ButtonComponent>
      </div>
    </div>
  );
};

export default LinkSharedComponent;
