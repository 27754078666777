import React from "react";
import classNames from "classnames";
import { useStyles } from "./style";

const LabelComponent = ({
  children,
  extraClass,
  theme,
  mandatory,
  labelExtraIcon,
  value,
}: any) => {
  const classes = useStyles(theme);
  return (
    <label
      className={classNames(classes.formLabel, extraClass, "formLabelCommon")}
      htmlFor={children}
    >
      {children} {mandatory && <span className="text-danger">*</span>}
      {labelExtraIcon && labelExtraIcon}
      {value && value}
    </label>
  );
};

export default LabelComponent;
