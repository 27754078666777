import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  merCard: {
    borderRadius: "10px",
    backgroundColor: "var(--white)",
    boxShadow: "rgb(140 149 159 / 2%) 0px 8px 24px 0px",
    [breakpoints.SCREEN_MD_MIN]: {
      padding: "30px",
    },
    [breakpoints.SCREEN_MD_MAX]: {
      padding: "20px",
    },
    // payList
    "& .payList": {
      "& .plItems": {
        overflow: "hidden",
        maxWidth: "calc(100% - 1px)",
        textOverflow: "ellipsis",
        "&:not(:last-child)": {
          marginBottom: "10px",
        },
        "& p": {
          fontSize: "15px",
          paddingRight: "8px",
          whiteSpace: "nowrap",
          color: "var(--lightGrey)",
          fontWeight: "var(--fontWeight500)",
        },
        "& h4": {
          fontSize: "17px",
          color: "var(--dark)",
          fontWeight: "var(--fontWeight700)",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          "& > span": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
          "& > svg": {
            flex: "0 0 13px",
          },
        },
      },
    },
  },
});
